
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppLauncher } from "@capacitor/app-launcher";

import "viewerjs/dist/viewer.css";
import { api } from "v-viewer";

type Tile = {
  name: string;
  default_icon?: string;
  icon: {
    url?: string;
  };
  type: {
    id: number;
    name: string;
  };
  contentable: {
    translation: {
      url: string;
    };
  };
};

@Component
export default class CategoryComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  async openUrl(url: string) {
    await AppLauncher.openUrl({ url });
  }

  iframe(tile: Tile): boolean {
    if (tile.type.name != "iFrame") return false;

    return tile.contentable.translation.url.match(/jpg|jpeg|png/g) != null;
  }

  show(url: string) {
    api({
      images: [url],
      options: {
        title: false,
        navbar: false,
        toolbar: false,
        toggleOnDblclick: false
      }
    });
  }
}
